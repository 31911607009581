<template>
    <div class="menu-dropdown__user">
        <div class="user__wrap">
            <router-link to="/auth/profile" class="user__data">
                <avatar :src="current_user.image_zoom_out"/>
                <span class="user__name">{{current_user.first_name}}</span>
            </router-link>
            <a @click="logout" title="Выйти" class="user__logout"/>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
    name: "User",
    components: {
        Avatar : () => import('@/components/Avatar')
    },
    computed: {
        ...mapGetters('default_data', [
            'current_user'
        ]),
    },
    methods: {
        logout () {
            this.$store.dispatch('auth/logout').then(() => this.$router.push('/login'))
        }
    },
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.menu-dropdown {
    &__user {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        .user {
            &__wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }

            &__name {
                color: var(--user-text-color);
                margin-left: 10px;
            }

            &__logout {
                @include icon($logout-icon, 14px)
            }
        }
    }
}
</style>
